import { XIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export default function AnnouncementPopup({ message, xPosition, yPosition, setShowPopup }) {
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const popupRef = useRef(null);

  useEffect(() => {
    if (popupRef.current) {
      const { x, y } = popupRef.current.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;
      if (x + popupRef.current.offsetWidth > innerWidth) {
        setXPos(innerWidth - popupRef.current.offsetWidth - 10);
      }
      if (y + popupRef.current.offsetHeight > innerHeight) {
        setYPos(innerHeight - popupRef.current.offsetHeight - 10);
      }
    }
  }, [popupRef]);

  useEffect(() => {
    setXPos(xPosition);
    setYPos(yPosition);
  }, [xPosition, yPosition]);

  return (
    <div
      ref={popupRef}
      className={twMerge(
        `absolute z-50 max-w-screen-sm min-w-[350px] min-h-[350px] rounded-md p-5 bg-white text-black shadow-lg`
      )}
      style={{ top: yPos, left: xPos }}>
      <button onClick={() => setShowPopup(false)}>
        <XIcon className="absolute top-2 right-2 h-5 w-5 cursor-pointer text-black" />
      </button>
      {message}
    </div>
  );
}
