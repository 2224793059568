import React from "react";
import { Heading } from "../../components";
import { classNames } from "../../utils";

export default function ContentContainer(props) {
  const { children, heading, className } = props;

  return (
    <section className={classNames(className, "max-w-7xl mx-auto w-full")}>
      {heading && <Heading text={heading} />}
      {children}
    </section>
  );
}
