import React from "react";
import classNames from "../../utils/classNames";

export default function AvatarPlaceholder(props) {
  const { theme } = props
return(
    <svg 
    className={classNames(
      theme === 'green' ? "bg-bgGreen-dark text-bgGreen" : "text-gray-300", "h-full w-full"
    )}
    fill="currentColor"
    width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.25 8.75V2.75H10.25L12.7175 5.2175L5.2175 12.7175L2.75 10.25V16.25H8.75L6.2825 13.7825L13.7825 6.2825L16.25 8.75Z" fill="#6B7280"/>
    </svg>
  );
}