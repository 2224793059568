import React from "react";

export default function MediumButton(props) {
  const { children, name, type, click, disabled = false, loading = false, CircularLoader } = props;

  const isDisabled = disabled || loading;
  const buttonText = children || name;

  return (
    <button
      disabled={isDisabled}
      onClick={click}
      className={`
        relative
        ${
          loading
            ? type === "solid"
              ? "bg-turquoise opacity-75"
              : "border border-gray-300 text-gray-500 bg-gray-50"
            : disabled
            ? "bg-gray-300 hover:bg-gray-300 cursor-not-allowed"
            : type === "solid"
            ? "bg-turquoise hover:bg-turquoise-dark"
            : "border border-gray-300 text-gray-700 hover:bg-gray-50"
        }
        text-center px-6 py-2 rounded-md shadow-md text-white w-3/4 md:w-fit
        transition-all duration-200
        ${loading ? "cursor-not-allowed" : ""}
      `}>
      <span className={`flex items-center justify-center gap-2 ${loading ? "opacity-0" : ""}`}>{buttonText}</span>

      {loading && (
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center gap-2">
          <CircularLoader className="w-5 h-5" />
        </span>
      )}
    </button>
  );
}
