import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Divider, Tabs, Title } from "../../../components";
import { ContentContainer, EmbedContainer, FullPageContainer } from "../../../containers";
import { useOnboardingUser } from "../../../hooks";
import WorkbookDone from "./WorkbookDone";
import WaiverTabLabel from "./WorkbookTabLabel";
import tabs from "../../../config/content/workbook";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";


export default function Workbook() {
  useDocumentTitle('Workbook')  
  const { user } = useOnboardingUser();
  const [currentTab, setCurrentTab] = useState(null);
  const [tabComponents, setTabComponents] = useState(tabs);

  useEffect(() => {
    _.each(tabs, (tab) => {
      const status = user?.[tab.statusKey] ?? false;
      tab.status = status;
      tab.label = <WaiverTabLabel isCompleted={status} label={tab.name} />;
    });
    setTabComponents([...tabs]);
  }, [user]);

  useEffect(() => {
    setCurrentTab(_.first(tabs));
  }, []);

  const getFormUrl = (tab) => {
    const baseUrl = tab.src;
    const fullName = `${user?.firstname} ${user?.lastname}`;
    const params = new URLSearchParams({
      embedded: 'true',
      usp: 'pp_url',
      [tab.entryFields.name]: fullName,
      [tab.entryFields.email]: user?.email
    });
    console.log('params', baseUrl, params.toString())
    return `${baseUrl}&${params.toString()}`;
  };

  return !currentTab ? null : (
    <FullPageContainer>
      <Title>Training Modules</Title>
      <ContentContainer>
        <p>
          The training modules are self-paced and include information that will provide a 
          foundation for your tutoring sessions. The forms may take a few moments to update. Thank you for your patience.
        </p>
      </ContentContainer>

      <ContentContainer>
        <Tabs value={currentTab.name} onChange={setCurrentTab} tabs={tabComponents} />
      </ContentContainer>

      <Divider />

      {!currentTab.status ? (
        <EmbedContainer 
          src={getFormUrl(currentTab)} 
          title="Workbook"
        />
      ) : (
        <WorkbookDone name={currentTab.name} />
      )}
    </FullPageContainer>
  );
}