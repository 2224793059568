import { createContext } from "react";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import firebaseConfig from "../config/firebase";

export const FirebaseContext = createContext({});

export const FirebaseProvider = ({ children }) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const functions = getFunctions(app);

  const services = {
      getCustomAuthToken: httpsCallable(functions, "getCustomAuthToken"),
      getOnboardingTutor: httpsCallable(functions, "getOnboardingTutor"),
      getStudentPathway: httpsCallable(functions, "getStudentPathway"),
      getTutor: httpsCallable(functions, "getTutor"),
      launchLearningPortalForStudent: httpsCallable(functions, "launchLearningPortalForStudent"),
      sendEmailSignInLink: httpsCallable(functions, "sendEmailSignInLink"),
      updateLessonCompletionStatus: httpsCallable(functions, "updateLessonCompletionStatus"),
      createNewOnboardingUser: httpsCallable(functions, "createNewOnboardingUser"),
      setLatestViewedAnnouncement: httpsCallable(functions, "setLatestViewedAnnouncement"),
      addEventAttendee: httpsCallable(functions, "addEventAttendee"),
      getPortalAnnouncements: httpsCallable(functions, "getPortalAnnouncements"),
      getActionItems: httpsCallable(functions, "getActionItems"),
      completeActionItem: httpsCallable(functions, "completeActionItem"),
      setAnnouncementStatus: httpsCallable(functions, "setAnnouncementStatus"),
  };

  // TODO: Implement analytics
  // const analytics = {};

  // TODO: Implement loggers
  // const logger = {};

  if (window.location.hostname === "localhost") {
    console.log("Firebase running on localhost");
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFunctionsEmulator(functions, "localhost", 5001);
  }

  return <FirebaseContext.Provider value={{ app, auth, functions, services }}>{children}</FirebaseContext.Provider>;
};
