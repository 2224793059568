export const ALERT_VARIANTS = {
  FAILURE: "failure",
  INFO: "info",
  SUCCESS: "success",
  WARN: "warn",
};

export const FIREBASE_FUNCTIONS = {
  GET_CUSTOM_AUTH_TOKEN: "getCustomAuthToken",
  GET_ONBOARDING_TUTOR: "getOnboardingTutor",
  GET_TUTOR: "getTutor",
  SEND_EMAIL_SIGN_IN_LINK: "sendEmailSignInLink",
};

export const ONBOARDING_TASKS = {
  CHAT: "Chat",
  WAIVER: "Waiver",
  WORKBOOK: "Workbook",
  BACKGROUND_CHECK: "Background Check",
  // LIVE_TRAINING: "Live Training",
  SUPPORT: "Support"
};

export const ONBOARDING_STATUS = {
  APP_ACCEPTED: "Application Accepted",
  TUTOR_READY: "Ready to Tutor",
  MATCHED: "Matched",
};

export const ONBOARDING_STATUS_INDEX = {
  [ONBOARDING_STATUS.APP_ACCEPTED]: 0,
  [ONBOARDING_STATUS.TUTOR_READY]: 1,
  [ONBOARDING_STATUS.MATCHED]: 2,
};

export const TASK_STATUS = {
  COMPLETE: "Completed",
  INCOMPLETE: "Not Completed",
  AWAITING: "Awaiting Interview"
};

export const STEPS_STATUS = {
  COMPLETE: "complete",
  CURRENT: "current",
  PENDING: "pending",
};

export const ROUTE_PATHS = {
  LANDING: "/",
  ONBOARDING_HOME: "onboarding",
  ONBOARDING_CHAT: "chat",
  ONBOARDING_WAIVER: "waiver",
  ONBOARDING_WORKBOOK: "workbook",
  ONBOARDING_BACKGROUND_CHECK: "background-check",
  ONBOARDING_TRAINING: "training",
  ONBOARDING_SUPPORT: "support",
  TUTOR_HOME: "tutor",
};

export const EXTERNAL_LINKS = {
  ONBOARDING_WAIVER: "https://tqp1uzj8.paperform.co",
  EMAIL_US: "mailto:onboarding@stepuptutoring.org",
  OFFICE_HOURS: "https://StepUpTutoring.as.me/experiencemanagerofficehours",
  ONBOARDING_SUPPORT_MEETING: "https://StepUpTutoring.as.me/askaquestion",
  ONBOARDING_PORTAL_FEEDBACK_FORM:
    "https://docs.google.com/forms/d/e/1FAIpQLSdeHf6-UEJWTxKcUsLBUuQZTD1Ij_Lbo9Pf8ddaEi7lyLVabQ/viewform",
  ONBOARDING_FAQ:
    "https://stepuptutoringhelp.zendesk.com/hc/en-us/sections/26778799243924-Onboarding",
  TRAINING_RESOURCES:
    "https://help.stepuptutoring.org/hc/en-us/sections/28482422843028-Training-Materials-Content",
  TUTOR_PORTAL_FEEDBACK_FORM:
    "https://docs.google.com/forms/d/e/1FAIpQLScKVIWpj93Hjc66WXUsuI-0vCLLrC73r0OTSu2A7tbADhHRIA/viewform",
  VOLUNTEER_TUTOR_APPLICATION:
    "https://www.stepuptutoring.org/tutor-application",
  STUDENT_APPLICATION:
    "https://www.stepuptutoring.org/student#anchor",
  CURRICULUM:
    "https://stepuptutoring.softr.app/",
  WEEKLY_FORM:
  "https://airtable.com/appVTwOTC6wauyLBL/shrNNQzXOJOP7WtEm",
  CONTACT_SUPPORT_TEAM:
    "mailto:tutorsupport@stepuptutoring.org",
  HELP_CENTER:
    "https://stepuptutoringhelp.zendesk.com/hc/en-us/categories/25737139094036-Tutors",
  Add_DROP_STUDENTS:
    "https://airtable.com/shrpWchC5Axp6PEei",
  REQUEST_SUBSTITUTE:
    "https://airtable.com/appmQ8Do0E1egGZs6/shr622q14kusdaAJ3",
  FILL_IN_AS_SUBSTITUTE:
    "https://stepupsubs.softr.app/",
  UNIT_ONE_PREASSESSMENT: {
      "3rd": "https://zj23hamz.paperform.co/",
      "4th": "https://gwizypj1.paperform.co/",
      "5th": "https://iz1nk47t.paperform.co/",
      "6th": "https://rieycihy.paperform.co/",
      "7th": "https://crlqbtf1.paperform.co/"

  },
  FIRST_SESSION_GUIDES: "https://docs.google.com/document/d/1-THPdjj8KICj6HEpeo5PhZLtC4M4z09UdF6hE_BtK_0/edit#heading=h.9qo4m6dq6lxj",
  REPORT_A_BUG: "https://docs.google.com/forms/d/e/1FAIpQLScKVIWpj93Hjc66WXUsuI-0vCLLrC73r0OTSu2A7tbADhHRIA/formResponse",
  TIMESHEET: "https://airtable.com/appoL9R8VNBmJy0L5/shr3lCzRVTFfgEfyC?prefill_Source%20data=form&hide_Source%20data=true",
  STUDENT_PORTAL: "https://student-portal.stepuptutoring.org/student/",
  DAILY_TIME_SHEET: "https://airtable.com/appoL9R8VNBmJy0L5/shr3lCzRVTFfgEfyC?prefill_Source%20data=form&hide_Source%20data=true",
};
