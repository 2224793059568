export default function AnnouncementModalItem({ subject, message, createdAt }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = months[date.getMonth()];
    const day = date.getDate();
    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) return "th"; // Handle special cases for 11th, 12th, 13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }
    const dayWithSuffix = day + getOrdinalSuffix(day);
    return `${monthName} ${dayWithSuffix}`;
  };

  return (
    <div className="rounded-[12px] mb-2">
      <div className="text-sm py-3 text-[#6B7280]">{formatDate(createdAt)}</div>
      <div className="pl-5">
        <div className="text-base font-bold text-[#1F2937]">{subject}</div>
        <div className="text-sm py-3 text-[#1F2937]">{message}</div>
        {/* <div className="text-sm py-3 text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">{message}</div> */}
      </div>
    </div>
  );
}
