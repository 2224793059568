import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import LoaderPage from "../utility/LoaderPage";
import { useRouter } from "../../hooks";
import { useCallback, useEffect, useState } from "react";
import useNewUserData from "../../hooks/useNewUserData";
import { ExternalLink } from "../../components";
import LargeButton from "../../components/core/LargeButton";

export default function UserCreate() {
  useDocumentTitle("Setting up Appointment Scheduling");
  const { query, navigate } = useRouter();
  const { services } = useNewUserData();
  const [errorCondition, setErrorCondition] = useState(false);
  const [errorConditionMessage, setErrorConditionMessage] = useState(null);

  const createNewOnboardingUser = useCallback(() => {
    return services
      .createNewOnboardingUser({
        email: query["email"],
        firstname: query["firstname"],
        lastname: query["lastname"],
        phonenum: query["phonenum"],
      })
      .then((value) => {
        let result = value.data;

        if (result.error) return { error: result.error, type: result.type };

        return { link: result.instantSignInLink };
      })
      .catch((error) => {
        return { error };
      });
  }, [query, services]);

  useEffect(() => {
    createNewOnboardingUser().then((value) => {
      if (value.error) {
        setErrorCondition(true);
        switch (value.type) {
          case "dup-email":
            setErrorConditionMessage("A tutor with this email already exists.");
            break;
          case "invalid-submission":
            setErrorConditionMessage(
              "The information submitted was improperly formatted. Please go back and try again."
            );
            break;
          default:
            setErrorConditionMessage("Something went wrong. Please try again.");
        }
        console.error(value.error);
        return;
      }
      let url = new URL(value.link);
      const react_url = `${url.pathname}${url.search}`;
      navigate.replace({ to: react_url });
    });
  }, [createNewOnboardingUser, navigate]);

  return errorCondition ? (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-6 text-center">
      <h2 className="text-2xl font-bold text-red-600 mb-2">
        {errorConditionMessage || "Something went wrong. Please try again."}
      </h2>

      <p className="text-gray-700 mb-8">
      Please try requesting a new link from the sign-in page. {" "}
        </p>

        <ExternalLink href="https://step-up-portal.web.app/auth" className="text-blue-600 hover:underline mb-12">
          <LargeButton
            primaryColor="turquoise"
            type="solid"
            onClick={() => navigate("/auth", { replace: true })}
        >
            Sign In to Onboarding Portal
          </LargeButton>{" "}
        </ExternalLink>

      <p className="text-sm text-gray-500 italic mb-2">
        If you are an active tutor, you should sign into the Tutor Portal with your Step Up email{" "}
        <ExternalLink href="https://step-up-portal.web.app/tutoring" className="text-blue-600 hover:underline">
          here
        </ExternalLink>
        .
      </p>

      <p className="text-gray-700">
        If you continue to have issues, please contact us at{" "}
        <ExternalLink href="mailto:onboarding@stepuptutoring.org" className="text-blue-600 hover:underline">
          onboarding@stepuptutoring.org
        </ExternalLink>
        .
      </p>
    </div>
  ) : (
    <LoaderPage />
  );
}
