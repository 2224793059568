import React, { useContext, useState } from "react";
import MediumButton from "../../components/core/MediumButton";
import { ExternalLink } from "../../components";
import { FirebaseContext } from "../../contexts";
import CircularLoader from "../../components/core/CircularLoader";
import { useTutoringUser } from "../../hooks";

const isTest = process.env.REACT_APP_TEST === "true";
const SUT_LEARNING_SPACE_URL = `https://${isTest ? "test-" : ""}learning-portal.stepuptutoring.org`;
const LEARNING_PORTAL_GUIDE_URL =
  "https://stepuptutoringhelp.zendesk.com/hc/en-us/articles/26497297343892-Learning-Portal-Tech-Guide";

export default function StudentRow(props) {
  const { student, setStudentInfo } = props;
  const { user } = useTutoringUser();
  // const calendarLink = https://www.google.com/calendar/render?action=TEMPLATE&text=Step%20Up%20Tutoring%20session%20with%20${encodeURIComponent(student.firstname)}%20${encodeURIComponent(student.lastname)}&dates=20220101T010000Z/20220101T020000Z&details=${encodeURIComponent(MEETING_BASE_URL)}/${student.meetingId}
  const { services } = useContext(FirebaseContext);
  const [learningSpaceUrlLoading, setLearningSpaceUrlLoading] = useState(false);

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

  function openLearningSpaceForStudent(studentId) {
    setLearningSpaceUrlLoading(true);
    console.log("openLearningSpaceForStudent", studentId);
    services
      .launchLearningPortalForStudent({ studentId })
      .then((res) => {
        // Open the learning space replacing current location
        const url = `${SUT_LEARNING_SPACE_URL}/login?url=${encodeURIComponent(res.data)}&name=${encodeURIComponent(
          `${capitalize(student.firstname)} and ${capitalize(user.firstname)}`
        )}&eedi=${encodeURIComponent(student.eedi)}&subjects=${encodeURIComponent(student.subjects?.join(","))}`;
        window.open(url, "_blank").focus();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLearningSpaceUrlLoading(false);
      });
  }
  return (
    <tr className="block lg:table-row">
      <td className="whitespace-nowrap py-4 justify-center pl-4 pr-3 text-lg text-center text-gray-900">
        <div className="font-bold">{`${student.firstname} ${student.lastname}`}</div>
        <div className="text-xs text-gray-500 underline hover:cursor-pointer">
          <span onClick={() => setStudentInfo(student)}>View Student Details</span>
        </div>
      </td>

      <td className="flex flex-col lg:table-cell whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
        <div className="flex flex-row text-base justify-around">
          <MediumButton
            click={() => openLearningSpaceForStudent(student.id)}
            type="solid"
            loading={learningSpaceUrlLoading}
            CircularLoader={CircularLoader}>
            Start Meeting
          </MediumButton>
        </div>
        <div className="text-xs mt-1 text-gray-500 underline hover:cursor-pointer">
          <ExternalLink href={LEARNING_PORTAL_GUIDE_URL}>
            <span>Open Learning Portal Guide</span>
          </ExternalLink>
        </div>
      </td>
      <td className="flex flex-col lg:table-cell whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
        <div className="relative group inline-block">
          <MediumButton click={() => {}} disabled type="solid">
            View Messages
            <span className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity absolute left-full ml-2 px-2 py-1 bg-gray-900 text-xs font-medium text-white rounded-md shadow-sm whitespace-nowrap">
              Feature coming soon!
            </span>
          </MediumButton>
        </div>
      </td>
    </tr>
  );
}
