import React from "react";

export default function LargeButton(props) {
  const { children, name, type, click, disabled = false, primaryColor = "turquoise" } = props;

  const isSolid = type && type === "solid";
  const isPrimaryTurquoise = primaryColor === "turquoise";

  return (
    <button
      disabled={disabled}
      onClick={click}
      className={
        isSolid
          ? `text-center w-52 px-24 py-6 my-2 mx-4 rounded-lg shadow-md text-${
              isPrimaryTurquoise ? "gray-700" : "textGrey"
            } bg-${isPrimaryTurquoise ? "turquoise" : "white"} hover:bg-${
              isPrimaryTurquoise ? "turquoise-dark" : "hover-white"
            } md:w-fit`
          : `inline-flex items-center w-48 px-6 py-2 my-2 mx-4 rounded-lg shadow-md border border-gray-300 text-sm text-${
              isPrimaryTurquoise ? "off-white" : "textGrey"
            } hover:bg-${isPrimaryTurquoise ? "gray-50" : "hover-white"}`
      }
      style={{ borderRadius: "15px" }}>
      {children || name}
    </button>
  );
}
