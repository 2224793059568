import { useContext, useEffect, useMemo, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Card, ExpandIcon } from "../../../components";
import AnnouncementItem from "./AnnouncementItem";
import { useTutoringUser } from "../../../hooks";
import AnnouncementModalItem from "./AnnouncementModalItem";
import { XIcon } from "@heroicons/react/outline";
import AnnouncementPopup from "./AnnouncementPopup";
import { FirebaseContext } from "../../../contexts";

export default function Announcements() {
  const { announcements, readAnnouncementIds } = useTutoringUser();
  const { services } = useContext(FirebaseContext);
  const [readIds, setReadIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");

  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const [isShowPopup, setShowPopup] = useState(false);
  const showPopup = (e, message, id) => {
    setMessage(message);
    setXPosition(e.clientX);
    setYPosition(e.clientY);
    setShowPopup(true);
    services.setAnnouncementStatus({ announcementId: id }).then((res) => {
      setReadIds([...readIds, id]);
    });
  };

  const countAnnouncement = useMemo(
    () => announcements?.filter((announcement) => !readIds.includes(announcement.id)).length,
    [announcements, readIds]
  );

  const Header = () => {
    return (
      <div className="relative">
        <div className="flex items-center gap-2 justify-center relative">
          <span className="font-bold text-xl text-[#1F2937]">Announcements</span>
          {countAnnouncement > 0 && (
            <div className="rounded-[50px] bg-[#EF4444] text-white flex items-center justify-center text-sm w-[18px] h-[18px]">
              {countAnnouncement}
            </div>
          )}
        </div>
        {announcements?.length > 0 && (
          <div className="absolute right-0 top-0 mt-2 mr-2">
            <button className="bg-inherit border-none border-0" onClick={() => setShowModal(true)}>
              <ExpandIcon />
            </button>
          </div>
        )}
      </div>
    );
  };

  const Footer = () => {
    if (!announcements?.length) return null;

    return (
      <>
        {announcements?.length > 0 && (
          <button className="bg-inherit border-none border-0 text-sm text-[#0D9488]" onClick={() => setShowModal(true)}>
            View All Updates
            <ChevronRightIcon className="h-4 w-4 inline-block ml-1" />
          </button>
        )}
        {showModal && (
          <div className="fixed flex items-center justify-center top-0 left-0 w-screen h-screen bg-opacity-50 bg-gray-500 z-50">
            <div className="relative flex flex-col bg-white rounded-3xl overflow-hidden px-10 min-h-[80vh] z-50 items-center shadow-md w-full max-w-2xl max-h-[calc(100%-100px)]">
              <div className="w-full">
                <div className="text-3xl font-bold pt-8 pb-4 text-black">Announcements</div>
                <button onClick={() => setShowModal(false)} className="absolute top-5 right-5 text-black">
                  <XIcon className="w-5 h-5" />
                </button>
              </div>
              <div className="pb-5 w-full overflow-auto">
                <div>
                  {announcements?.length > 0 ? (
                    announcements?.map((announcement, index) => <AnnouncementModalItem key={index} {...announcement} />)
                  ) : (
                    <div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">
                      No available announcement
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    setReadIds(readAnnouncementIds);
  }, [readAnnouncementIds]);

  return (
    <>
      <Card header={<Header />} footer={<Footer />}>
        {announcements?.length > 0 ? (
          announcements
            .filter((announcement) => !readAnnouncementIds.includes(announcement.id))
            .map((announcement, index) => (
              <AnnouncementItem
                key={index}
                {...announcement}
                isRead={readIds?.includes(announcement.id)}
                showPopup={showPopup}
              />
            ))
        ) : (
          <div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">
            No available announcement
          </div>
        )}
      </Card>

      {isShowPopup && (
        <AnnouncementPopup message={message} setShowPopup={setShowPopup} xPosition={xPosition} yPosition={yPosition} />
      )}
    </>
  );
}
