import React from "react";
import classNames from "../../utils/classNames";

export default function SolidButton(props) {
  const { children, name, type = "outline" } = props;

  // TODO: Specify solid style classes
  return (
    <button
      className={classNames(
        type === "outline"
          ? "border-turquoise-dark bg-turquoise-dark text-white hover:border-hover-turquoise hover:bg-hover-turquoise"
          : "",
        "w-64 p-3 text-sm rounded-xl drop-shadow-md	"
      )}>
      {children || name}
    </button>
  );
}
